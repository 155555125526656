import * as React from "react";
import {FAQJsonLd} from "gatsby-plugin-next-seo";
import {graphql, useStaticQuery} from "gatsby";

function FaqSeo() {
  const data = useStaticQuery(graphql`
    query FAQsSEOQuery {
      faqs: markdownRemark(
        frontmatter: {templateKey: {eq: "frequently-asked-questions"}}
      ) {
        frontmatter {
          generalFAQs {
            question
            answer
          }
          specialEducationFAQs {
            question
            answer
          }
          tbControlFAQs {
            question
            answer
          }
          lifeLongCareFAQs {
            question
            answer
          }
          coursesFAQs {
            question
            answer
          }
          volunteersFAQs {
            question
            answer
          }
        }
      }
    }
  `);
  const {
    generalFAQs,
    specialEducationFAQs,
    tbControlFAQs,
    lifeLongCareFAQs,
    coursesFAQs,
    volunteersFAQs,
  } = data.faqs.frontmatter;
  return <FAQJsonLd
    questions={[
      ...generalFAQs, ...specialEducationFAQs, ...tbControlFAQs, ...lifeLongCareFAQs, ...coursesFAQs, ...volunteersFAQs
    ]}/>
}

export default FaqSeo;
