import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import EmphasisTextUnderlined from "../../components/emphasisTextUnderline";
import arrowLeftImage from '../../img/generic/arrowLeft.svg'

function FocusAreasSection() {
  const [curSectionIndex, _setCurSectionIndex] = React.useState(0);
  const [lastUpdated, setLastUpdated] = React.useState(null);

  const data = useStaticQuery(graphql`
    query FocusAreasSectionQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
        frontmatter {
          focusAreasSection {
            description
            title
            link
            image
          }
          focusAreasBackgroundImage
        }
      }
    }
  `);
  const {focusAreasSection: focusAreaDetails, focusAreasBackgroundImage} = data.markdownRemark.frontmatter;
  const focusAreasImages = focusAreaDetails.map(detail => detail.image);

  const setCurSectionIndex = React.useCallback((newIndex) => {
    _setCurSectionIndex(newIndex);
    setLastUpdated(Date.now());
  }, []);

  React.useEffect(() => {
    const transitionInterval = 3500; // secs
    const timeoutId = setTimeout(() => {
      setCurSectionIndex(c => (c + 1) % focusAreaDetails.length);
    }, transitionInterval);
    return () => clearTimeout(timeoutId);
  }, [lastUpdated, setCurSectionIndex, focusAreaDetails]);

  return (
    <div className="sm:flex flex-row">
      <div className="h-96 sm:h-auto flex-1 relative">
        <div className="absolute sm:top-10 sm:bottom-10 w-full">
          <img src={focusAreasBackgroundImage} className="h-full w-full object-cover"/>
          <div className="absolute top-10 bottom-10 left-10 right-10">
            {focusAreasImages.map((focusAreaImage, index) => (
              <img key={index} src={focusAreaImage} className={`absolute h-full w-full transition-all duration-500
              object-contain ${curSectionIndex === index ? "opacity-100" : "opacity-0"}`}/>
            ))}
          </div>
        </div>
        <div className="hidden sm:block ml-auto w-1/4 bg-red-900 h-full"></div>
      </div>
      <div className="relative flex-1 bg-red-900 py-10">
        <h3 className="mt-20 text-white text-2xl px-10">FOCUS AREAS</h3>

        {focusAreaDetails.map(({title, description, link}, index) => (
          <div key={index} className={`absolute transition-all duration-500 w-full px-10 ${
            index === curSectionIndex ? "opacity-100" : "opacity-0"}`}>
            <EmphasisTextUnderlined
              text={title} strokeType="strokeSmallWhite"
              className="mt-10 font-emphasis text-8xl text-yellow-400"/>
            <p className="mt-10 text-white text-2xl">
              {description}
            </p>
            {index === curSectionIndex && (
              <a href={link}
                 className="block w-fit mt-10 text-white border-2 px-8 py-4 rounded-full hover:border-0
               hover:bg-black hover:text-white">
                KNOW MORE
              </a>
            )}
          </div>
        ))}

        <div className="mt-[28rem] flex flex-row items-center px-4 pb-10">
          <button
            onClick={() => setCurSectionIndex((focusAreaDetails.length + curSectionIndex - 1) % focusAreaDetails.length)}>
            <img src={arrowLeftImage} className="w-12 h-12"/></button>
          <div className="flex-grow flex flex-row justify-center flex-wrap">
            {focusAreaDetails.map(({title}, index) => (
              <button key={index} onClick={() => setCurSectionIndex(index)} className="p-2">
                <span className={`transition-all duration-500 text-yellow-500 ${
                  index === curSectionIndex ? "opacity-100" : "opacity-50"}`}>
                  {title}
                </span>
              </button>
            ))}
          </div>
          <button
            onClick={() => setCurSectionIndex((curSectionIndex + 1) % focusAreaDetails.length)}>
            <img src={arrowLeftImage} className="w-12 h-12 rotate-180"/></button>
        </div>
      </div>
    </div>
  )
}

export default FocusAreasSection;
