import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import EmphasisTextUnderlined from "../../components/emphasisTextUnderline";
import DonateButton from "../../components/donateButton";
import FloatingSection from "../../components/floatingSection";

function MissionSection() {
  const data = useStaticQuery(graphql`
    query MissionSectionQuery {
      markdownRemark(
        frontmatter: {templateKey: {eq: "index-page"}}
      ) {
        frontmatter {
          missionSection {
            heading1
            heading2
            description
            bullet1
            bullet2
            bullet3
            image
          }
        }
      }
    }
  `);
  const {
    heading1,
    heading2,
    description,
    bullet1,
    bullet2,
    bullet3,
    image: missionSectionBackground
  } = data.markdownRemark.frontmatter.missionSection;

  return (
    <div className="relative flex flex-row">
      <div className="z-10 relative md:flex-1">
        <FloatingSection>
          <div className="mt-40 pl-5 md:pl-20 xl:pl-60 pr-5">
            <EmphasisTextUnderlined text={heading1} strokeType="strokeSmallYellow"
                                    containerClassName="mx-auto md:mx-0"
                                    className="font-emphasis text-8xl -mb-6"/>
            <EmphasisTextUnderlined text={heading2} strokeType="strokeLargeYellow"
                                    containerClassName="mx-auto md:mx-0"
                                    className="font-emphasis text-8xl mb-10 text-center md:text-left"/>
            <p>{description}</p>
            <ul className="list-disc ml-5 text-justify">
              <li className="mt-5">{bullet1}</li>
              <li className="mt-5">{bullet2}</li>
              <li className="mt-5">{bullet3}</li>
            </ul>

            <div className="mt-10 mb-10 text-center md:text-left">
              <DonateButton/>
            </div>
          </div>
        </FloatingSection>
      </div>
      <div className="absolute h-full md:h-auto opacity-30 md:opacity-100 md:static md:flex-1">
        <img src={missionSectionBackground} alt="Mission Section Background" className="object-contain h-full w-full"/>
      </div>
    </div>
  );
}

export default MissionSection;
