import React from "react";

import Layout from "../../components/layout";
import MissionSection from "./missionSection";
import ProblemStatsSection from "./problemStatsSection";
import FocusAreasSection from "./focusAreasSection";
import ImpactStatsSection from "./impactStatsSection";
import SupportUsSection from "./supportUsSection";
import HelpMethodsSection from "../../components/helpMethodsSection";
import FaqSeo from "../frequently-asked-questions/faqSeo";

const IndexPage = (props) => {

  return (
    <Layout dynamicNavbar {...props}>
      <FaqSeo/>
      <MissionSection/>
      <ProblemStatsSection/>
      <FocusAreasSection/>
      <ImpactStatsSection/>
      <SupportUsSection/>
      <HelpMethodsSection/>
      </Layout>
  );
};

IndexPage.propTypes = {};

export default IndexPage;
