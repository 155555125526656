import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import EmphasisTextUnderlined from "../../components/emphasisTextUnderline";
import FloatingSection from "../../components/floatingSection";
import DonateButton from "../../components/donateButton";

function SupportUsSection() {
  const data = useStaticQuery(graphql`
    query SupportUsSectionQuery {
      markdownRemark(
        frontmatter: {templateKey: {eq: "index-page"}}
      ) {
        frontmatter {
          supportUsSection
          supportUsSectionBackgroundImage
        }
      }
    }
  `);
  const {supportUsSection: sections, supportUsSectionBackgroundImage} = data.markdownRemark.frontmatter;

  return (
    <div className="relative">
      <div className="absolute h-full w-full">
        <img className="h-full w-full object-cover" src={supportUsSectionBackgroundImage}/>
      </div>
      <div className="absolute bg-red-900 h-full w-full md:w-1/2 opacity-90"/>
      <FloatingSection>
        <div className="h-full md:w-1/2 pt-20 px-10 md:pl-20 relative">
          <EmphasisTextUnderlined text="Support Us" strokeType="strokeSmallWhite"
                                  className="font-emphasis text-8xl text-yellow-400"/>
          <ul className="ml-5 mr-5">
            {sections.map((description, index) => (
              <li key={index} className="text mt-10 text-white list-disc">{description}</li>
            ))}
          </ul>
          <div className="mt-10 pb-40 text-center md:text-left">
            <DonateButton variant="yellow"/>
          </div>
        </div>
      </FloatingSection>
    </div>
  );
}

export default SupportUsSection;
