import React from "react";

import FloatingSection from "../../components/floatingSection";
import {graphql, useStaticQuery} from "gatsby";
import statsImage1 from "../../img/pages/index/problemStats1.png";
import statsImage2 from "../../img/pages/index/problemStats2.svg";
import statsImage3 from "../../img/pages/index/problemStats3.svg";

const statsImages = [statsImage1, statsImage2, statsImage3];

function ProblemStatsSection() {
  const data = useStaticQuery(graphql`
    query ProblemStatsSectionQuery {
      markdownRemark(
        frontmatter: {templateKey: {eq: "index-page"}}
      ) {
        frontmatter {
          problemStatsSection
        }
      }
    }
  `);
  const allStats = data.markdownRemark.frontmatter.problemStatsSection;

  return (
    <FloatingSection className="-mt-24">
      <div className="md:flex flex-row">
        {allStats.map((description, index) => (
          <div key={index} className="md:flex-1 p-20 bg-[url('../img/backgrounds/background1.jpg')] bg-contain">
            <img src={statsImages[index]} alt={`Stats Icon ${index + 1}`} className="w-24 h-24 mx-auto"/>
            <p className="mt-10 text-center">
              {description}
            </p>
          </div>
        ))}
      </div>
    </FloatingSection>
  )
}

export default ProblemStatsSection;
