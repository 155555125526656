import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import FloatingSection from "../../components/floatingSection";
import statsImage1 from '../../img/pages/index/impactStats1.svg';
import statsImage2 from '../../img/pages/index/impactStats2.svg';
import statsImage3 from '../../img/pages/index/impactStats3.svg';
import statsImage4 from '../../img/pages/index/impactStats4.svg';

const statsImages = [statsImage1, statsImage2, statsImage3, statsImage4];
const statsBackgrounds = [
  "bg-[url('../img/backgrounds/background1.jpg')]",
  "bg-[url('../img/backgrounds/background2.jpg')]",
  "bg-[url('../img/backgrounds/background3.jpg')]",
  "bg-[url('../img/backgrounds/background1.jpg')]",
];

function ImpactStatsSection() {
  const data = useStaticQuery(graphql`
    query ImpactStatsSectionQuery {
      markdownRemark(
        frontmatter: {templateKey: {eq: "index-page"}}
      ) {
        frontmatter {
          impactStatsSection {
            impactQty
            description
          }
        }
      }
    }
  `);

  const allStats = data.markdownRemark.frontmatter.impactStatsSection;

  return (
    <FloatingSection className="-mt-20 bg-white">
      <div className="md:flex flex-row md:w-3/4 mx-auto flex-wrap">
        {allStats.map(({impactQty, description}, index) => (
          <div key={index} className={`flex-1 p-10 ${statsBackgrounds[index]} bg-contain`}>
            <img src={statsImages[index]} className="w-36 h-36 mx-auto"/>
            <p className="mt-2 text-4xl text-center text-red-800 font-bold">
              {impactQty}
            </p>
            <p className="mt-2 text-center">
              {description}
            </p>
          </div>
        ))}
      </div>
    </FloatingSection>
  )
}

export default ImpactStatsSection;
